import { sectionNames } from "@dashboard/intl";
import { asSortParams } from "@dashboard/utils/sort";
import { parse as parseQs } from "qs";
import React from "react";
import { useIntl } from "react-intl";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { WindowTitle } from "../components/WindowTitle";
import {
  contactUsFormListPath,
  ContactUsFormListUrlQueryParams,
  ContactUsFormListUrlSortField,
  contactUsFormPath,
  ContactUsFormUrlQueryParams,
} from "./urls";
import ContactUsFormListComponent from "./views/ContactUsFormList";
import ContactUsFormDetailsView from "./views/ContactUsFormDetails";
interface ContactUsFormDetailsRouteParams {
  id: string;
}

const ContactUsFormDetails: React.FC<
  RouteComponentProps<ContactUsFormDetailsRouteParams>
> = ({ location, match }) => {
  const qs = parseQs(location.search.substr(1));
  const params: ContactUsFormUrlQueryParams = qs;
  return (
    <ContactUsFormDetailsView
      id={decodeURIComponent(match.params.id)}
      params={params}
    />
  );
};

const ContactUsFormList: React.FC<RouteComponentProps<any>> = ({
  location,
}) => {
  const qs = parseQs(location.search.substr(1)) as any;
  const params: ContactUsFormListUrlQueryParams = asSortParams(
    qs,
    ContactUsFormListUrlSortField,
    ContactUsFormListUrlSortField.dateCreated,
    false,
  );
  return <ContactUsFormListComponent params={params} />;
};

export const ContactUsFormSection = () => {
  const intl = useIntl();
  return (
    <>
      <WindowTitle title={intl.formatMessage(sectionNames.contactUs)} />
      <Switch>
        <Route
          exact
          path={contactUsFormListPath}
          component={ContactUsFormList}
        />
        <Route
          path={contactUsFormPath(":id")}
          component={ContactUsFormDetails}
        />
      </Switch>
    </>
  );
};
