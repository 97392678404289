import { ContactUsFormListUrlSortField } from "@dashboard/contactUsForms/urls";
import { ContactUsFormSortField } from "@dashboard/graphql";
import { createGetSortQueryVariables } from "@dashboard/utils/sort";

export const DEFAULT_SORT_KEY = ContactUsFormSortField.DATE_CREATED;

export function getSortQueryField(
  sort: ContactUsFormListUrlSortField,
): ContactUsFormSortField {
  switch (sort) {
    case ContactUsFormListUrlSortField.fullname:
    case ContactUsFormListUrlSortField.email:
    case ContactUsFormListUrlSortField.isClosed:
    case ContactUsFormListUrlSortField.dateCreated:
      return ContactUsFormSortField.DATE_CREATED;
    default:
    return undefined;
  }
}

export const getSortQueryVariables =
  createGetSortQueryVariables(getSortQueryField);
