import { FilterElement } from "@dashboard/components/Filter";
import { MultiAutocompleteChoiceType } from "@dashboard/components/MultiAutocompleteSelectField";
import { ShopAndGoOrderFilterInput } from "@dashboard/graphql";
import { parseBoolean } from "@dashboard/misc";
import {
  ShopAndGoOrderListFilterKeys,
  ShopAndGoOrderListFilterOpts,
} from "@dashboard/shopAndGo/components/ShopAndGoOrderListPage/filters";
import {
  ShopAndGoOrderListFitersWithMultipleValues,
  ShopAndGoOrderListUrlFilters,
  ShopAndGoOrderListUrlFiltersEnum,
  ShopAndGoOrderListUrlQueryParams,
} from "@dashboard/shopAndGo/urls";
import {
  createFilterTabUtils,
  createFilterUtils,
  getGteLteVariables,
  getMinMaxQueryParam,
  getMultipleValueQueryParam,
  getSingleValueQueryParam,
} from "@dashboard/utils/filters";

export const SHOP_AND_GO_ORDER_LIST_FILTERS_KEY =
  "shopAndGoOrderListFiltersPresets";

export function getFilterOpts(
  params: ShopAndGoOrderListUrlFilters,
  selectedStores: MultiAutocompleteChoiceType[],
): ShopAndGoOrderListFilterOpts {
  return {
    isClosed: {
      active: params?.isClosed !== undefined,
      value: parseBoolean(params?.isClosed, true),
    },
    selectedStore: selectedStores
      ? {
          active: params?.selectedStore !== undefined,
          choices: selectedStores,
          value: (params?.selectedStore || []).map(store => store.value),
        }
      : null,
    dateCreated: {
      active: [params?.createdFrom, params?.createdTo].some(
        field => field !== undefined,
      ),
      value: {
        max: params?.createdTo || "",
        min: params?.createdFrom || "",
      },
    },
  };
}

export function getFilterQueryParam(
  filter: FilterElement<ShopAndGoOrderListFilterKeys>,
): ShopAndGoOrderListUrlFilters {
  const { name } = filter;

  switch (name) {
    case ShopAndGoOrderListFilterKeys.fullname:
      return getSingleValueQueryParam(
        filter,
        ShopAndGoOrderListUrlFiltersEnum.fullname,
      );
    case ShopAndGoOrderListFilterKeys.email:
      return getSingleValueQueryParam(
        filter,
        ShopAndGoOrderListUrlFiltersEnum.email,
      );
    case ShopAndGoOrderListFilterKeys.dateCreated:
      return getMinMaxQueryParam(
        filter,
        ShopAndGoOrderListUrlFiltersEnum.createdFrom,
        ShopAndGoOrderListUrlFiltersEnum.createdTo,
      );
    case ShopAndGoOrderListFilterKeys.selectedStore:
      return getMultipleValueQueryParam(
        filter,
        ShopAndGoOrderListUrlFiltersEnum.selectedStore,
      );
    case ShopAndGoOrderListFilterKeys.isClosed:
      return getSingleValueQueryParam(
        filter,
        ShopAndGoOrderListUrlFiltersEnum.isClosed,
      );
  }
}

export const storageUtils = createFilterTabUtils<string>(
  SHOP_AND_GO_ORDER_LIST_FILTERS_KEY,
);
export const { areFiltersApplied, getActiveFilters, getFiltersCurrentTab } =
  createFilterUtils<
    ShopAndGoOrderListUrlQueryParams,
    ShopAndGoOrderListUrlFilters
  >({
    ...ShopAndGoOrderListUrlFiltersEnum,
    ...ShopAndGoOrderListFitersWithMultipleValues,
  });

export function getFilterVariables(
  params: ShopAndGoOrderListUrlFilters,
): ShopAndGoOrderFilterInput {
  return {
    channels: params.selectedStore as unknown as string[],
    created: getGteLteVariables({
      gte: params.createdFrom,
      lte: params.createdTo,
    }),
    isClosed:
      params.isClosed !== undefined
        ? parseBoolean(params.isClosed, false)
        : undefined,
    search: params.query,
  };
}
