// @ts-strict-ignore
import {
  dateCell,
  pillCell,
  readonlyTextCell,
  textCell,
} from "@dashboard/components/Datagrid/customCells/cells";
import { GetCellContentOpts } from "@dashboard/components/Datagrid/Datagrid";
import { AvailableColumn } from "@dashboard/components/Datagrid/types";
import { ShopAndGoOrdersListQuery } from "@dashboard/graphql";
import { RelayToFlat, Sort } from "@dashboard/types";
import { getColumnSortDirectionIcon } from "@dashboard/utils/columns/getColumnSortDirectionIcon";
import { GridCell, Item } from "@glideapps/glide-data-grid";
import { IntlShape, useIntl } from "react-intl";
import { columnsMessages } from "./messages";
import { ShopAndGoOrderListUrlSortField } from "@dashboard/shopAndGo/urls";
import { DefaultTheme } from "@saleor/macaw-ui/next";
import { getStatusHue, transformShopAndGoOrderIsClosed } from "@dashboard/misc";
import { hueToPillColorDark, hueToPillColorLight } from "@dashboard/components/Datagrid/customCells/PillCell";
import { useTheme } from "@saleor/macaw-ui/next";


export const shopAndGoOrdersListStaticColumnAdapter = (
  emptyColumn: AvailableColumn,
  intl: IntlShape,
  sort: Sort<ShopAndGoOrderListUrlSortField>,
) =>
  [
    emptyColumn,
    {
      id: "email",
      title: intl.formatMessage(columnsMessages.email),
      width: 200,
    },
    {
      id: "fullname",
      title: intl.formatMessage(columnsMessages.fullName),
      width: 200,
    },
    {
      id: "phoneNumber",
      title: intl.formatMessage(columnsMessages.phoneNumber),
      width: 150,
    },
    {
      id: "selectedStore",
      title: intl.formatMessage(columnsMessages.selectedStore),
      width: 150,
    },
    {
      id: "isClosed",
      title: intl.formatMessage(columnsMessages.isClosed),
      width: 100,
    },
    {
      id: "dateCreated",
      title: intl.formatMessage(columnsMessages.dateCreated),
      width: 150,
    },
    {
      id: "orderQuery", 
      title: intl.formatMessage(columnsMessages.orderQuery),
      width: 250,
    }
  ].map(column => ({
    ...column,
    icon: getColumnSortDirectionIcon(sort, column.id),
  }));

interface GetCellContentProps {
  columns: AvailableColumn[];
  shopAndGoOrders: RelayToFlat<ShopAndGoOrdersListQuery["shopAndGoOrders"]>;
}

function getDatagridRowDataIndex(row, removeArray) {
  return row + removeArray.filter(r => r <= row).length;
}

export const useGetCellContent = ({
  columns,
  shopAndGoOrders,
}: GetCellContentProps) => {
  const intl = useIntl();
  const { theme } = useTheme();

  return (
    [column, row]: Item,
    { added, removed }: GetCellContentOpts,
  ): GridCell => {
    const columnId = columns[column]?.id;

    if (!columnId) {
      return readonlyTextCell("");
    }

    const rowData = added.includes(row)
      ? undefined
      : shopAndGoOrders[getDatagridRowDataIndex(row, removed)];

    switch (columnId) {
      case "email":
        return readonlyTextCell(rowData.email);
      case "fullname":
        return readonlyTextCell(rowData.fullname);
      case "phoneNumber":
        return readonlyTextCell(rowData.phoneNumber);
      case "selectedStore":
        return readonlyTextCell(rowData.selectedStore.slug);
      case "isClosed":
        return getIsClosedContent(intl, theme, rowData);      
      case "dateCreated":
          return getDateCellContent(rowData);   
      case "orderQuery":
          return readonlyTextCell(rowData.orderQuery);
      default:
        return textCell("");
    }
  };
};

export function getDateCellContent(
  rowData: RelayToFlat<ShopAndGoOrdersListQuery["shopAndGoOrders"]>[number],
) {
  return dateCell(rowData?.dateCreated);
}

export function getIsClosedContent(
  intl: IntlShape,
  theme: DefaultTheme,
  rowData: RelayToFlat<ShopAndGoOrdersListQuery["shopAndGoOrders"]>[number],
) {
  const status = transformShopAndGoOrderIsClosed(rowData.isClosed, intl);
  const statusHue = getStatusHue(status.status);

  if (status) {
    const color =
      theme === "defaultDark"
        ? hueToPillColorDark(statusHue)
        : hueToPillColorLight(statusHue);
    return pillCell(status.localized, color);
  }

  return readonlyTextCell("-");
}