// @ts-strict-ignore
import { IFilter } from "@dashboard/components/Filter";
import { ContactUsFormSubjectFilter, PermissionEnum } from "@dashboard/graphql";
import { commonMessages, contactUsFormMessage } from "@dashboard/intl";
import { FilterOpts, MinMax } from "@dashboard/types";
import {
  createBooleanField,
  createDateField,
  createOptionsField,
} from "@dashboard/utils/filters/fields";
import { defineMessages, IntlShape } from "react-intl";

export enum ContactUsFormFilterKeys {
  dateCreated = "dateCreated",
  isClosed = "isClosed",
  subject = "subject",
}

export interface ContactUsFormListFilterOpts {
  dateCreated: FilterOpts<MinMax>;
  isClosed: FilterOpts<boolean>;
  subject: FilterOpts<ContactUsFormSubjectFilter[]>;
}

const messages = defineMessages({
  dateCreated: {
    id: "66JlXG",
    defaultMessage: "Date Created",
    description: "contact us form date created",
  },
  isClosed: {
    id: "o1UZx5",
    defaultMessage: "Form is Closed",
    description: "contact us form is closed",
  },
  subject: {
    id: "mT4d1A",
    defaultMessage: "Form Subject",
    description: "contact us form subject",
  },
});



export function createFilterStructure(
  intl: IntlShape,
  opts: ContactUsFormListFilterOpts,
): IFilter<ContactUsFormFilterKeys> {
  return [
    {
      ...createDateField(
        ContactUsFormFilterKeys.dateCreated,
        intl.formatMessage(messages.dateCreated),
        opts.dateCreated.value,
      ),
      active: opts.dateCreated.active,
    },
    {
      ...createBooleanField(
        ContactUsFormFilterKeys.isClosed,
        intl.formatMessage(messages.isClosed),
        opts.isClosed.value,
        {
          positive: intl.formatMessage(contactUsFormMessage.isClosedTrue),
          negative: intl.formatMessage(contactUsFormMessage.isClosedFalse),
        },
      ),
      active: opts.isClosed.active,
      permissions: [PermissionEnum.MANAGE_CONTACT_US_FORMS],
    },
    {
      ...createOptionsField(
        ContactUsFormFilterKeys.subject,
        intl.formatMessage(commonMessages.subject),
        opts.subject.value,
        true,
        [
          {
            label: "CUSTOMER SERVICE",
            value: ContactUsFormSubjectFilter.CUSTOMER_SERVICE
          },
          {
            label: "INQUIRY",
            value: ContactUsFormSubjectFilter.INQUIRY
          },
          {
            label: "NONE",
            value: ContactUsFormSubjectFilter.NO_SUBJECT
          },
          {
            label: "ORDERS",
            value: ContactUsFormSubjectFilter.ORDERS
          },
        ]
      ),
      active: opts.subject.active
    }
  ];
}
