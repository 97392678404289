import React from "react";
import ContactUsFormUpdatePage, {
  ContactUsFormPageTab,
} from "../components/ContactUsFormUpdatePage";
import { ContactUsFormUrlQueryParams, contactUsFormListUrl } from "../urls";
import {
  ContactUsFormUpdateMutation,
  useContactUsFormDetailsQuery,
  useContactUsFormUpdateMutation,
} from "@dashboard/graphql";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { useIntl } from "react-intl";
import { commonMessages } from "@dashboard/intl";
import { ContactUsFormUpdateData } from "../components/ContactUsFormUpdatePage/form";
import { extractMutationErrors, maybe } from "@dashboard/misc";
import NotFoundPage from "@dashboard/components/NotFoundPage";
import { WindowTitle } from "@dashboard/components/WindowTitle";

export interface ContactUsFormDetailsProps {
  params: ContactUsFormUrlQueryParams;
  id: string;
}

export function getActiveTab(tabName: string): ContactUsFormPageTab | string {
  return tabName === ContactUsFormPageTab.contactUsForm
    ? ContactUsFormPageTab.contactUsForm
    : "Contact Us Form";
}

export const ContactUsFormDetails: React.FC<ContactUsFormDetailsProps> = ({
  id,
}) => {
  const { data, loading } = useContactUsFormDetailsQuery({
    displayLoader: true,
    variables: { id },
  });

  const navigate = useNavigator();
  const notify = useNotifier();
  const intl = useIntl();

  const contactUsForm = data?.contactUsForm;

  const handleContactUsFormUpdate = (data: ContactUsFormUpdateMutation) => {
    if (data.contactUsFormUpdate.errors.length > 0) {
      data.contactUsFormUpdate.errors.map(error => {
        notify({
          status: "error",
          title: error.code,
          text: error.message,
        });
      });
    } else
      notify({
        status: "success",
        text: intl.formatMessage(commonMessages.savedChanges),
      });
  };

  const [updateContactUsForm, updateResult] = useContactUsFormUpdateMutation({
    onCompleted: handleContactUsFormUpdate,
  });

  const handleUpdate = async (formData: ContactUsFormUpdateData) =>
    extractMutationErrors(
      updateContactUsForm({
        variables: {
          id,
          input: {
            isClosed: formData.isClosed,
          },
        },
      }),
    );

  if (contactUsForm === null) {
    return <NotFoundPage onBack={() => navigate(contactUsFormListUrl())} />;
  }

  return (
    <>
      {" "}
      {!loading && (
        <>
          <WindowTitle
            title={maybe(() => "Contact Us Form #" + data.contactUsForm.id)}
          />
          <ContactUsFormUpdatePage
            contactUsForm={maybe(() => data.contactUsForm)}
            disabled={loading}
            errors={updateResult.data?.contactUsFormUpdate.errors || []}
            onSubmit={handleUpdate}
            saveButtonBarState={updateResult.status}
          />
        </>
      )}
    </>
  );
};

export default ContactUsFormDetails;
