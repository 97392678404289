import { ContactUsFormListUrlSortField } from "@dashboard/contactUsForms/urls";
import { ListContactUsFormsQuery } from "@dashboard/graphql";
import { RelayToFlat } from "@dashboard/types";

export function getContactUsFormsLength(
  orders?: RelayToFlat<ListContactUsFormsQuery["contactUsForms"]>,
  loading?: boolean,
) {
  if (loading) {
    return 1;
  }

  if (orders?.length) {
    return orders.length;
  }

  return 0;
}

export function getColumnNameAndId(column: string): {
  columnName: ContactUsFormListUrlSortField;
  columnId?: string;
} {
  if (column.includes(":")) {
    const [columnName, columnId] = column.split(":");

    return {
      columnName: columnName as ContactUsFormListUrlSortField,
      columnId,
    };
  }

  return {
    columnName: column as ContactUsFormListUrlSortField,
  };
}

export function canBeSorted(sort: ContactUsFormListUrlSortField) {
  switch (sort) {
    case ContactUsFormListUrlSortField.fullname:
    case ContactUsFormListUrlSortField.isClosed:
    case ContactUsFormListUrlSortField.email:
    case ContactUsFormListUrlSortField.dateCreated:
      return true;
    default:
      return false;
  }
}
