import { useExitFormDialog } from "@dashboard/components/Form";
import { ContactUsFormDetailsFragment } from "@dashboard/graphql";
import useForm, { CommonUseFormResult } from "@dashboard/hooks/useForm";
import useHandleFormSubmit from "@dashboard/hooks/useHandleFormSubmit";
import React, { useEffect } from "react";

export interface ContactUsFormUpdateData {
  dateCreated: string;
  email: string;
  fullname: string;
  isClosed: boolean;
  message: string;
  phoneNumber: string;
  subject: string;
}

export interface ContactUsFormUpdateFormProps {
  children: (props: UseContactUsFormDetailsUpdateFormResult) => React.ReactNode;
  contactUsForm: ContactUsFormDetailsFragment;
  onSubmit: (data: ContactUsFormUpdateData) => Promise<any[]>;
  disabled: boolean;
}

export interface UseContactUsFormDetailsUpdateFormResult
  extends CommonUseFormResult<ContactUsFormUpdateData> {}

const getInitialData = (contactUsForm: ContactUsFormDetailsFragment) => ({
  dateCreated: contactUsForm.dateCreated,
  email: contactUsForm.email,
  fullname: contactUsForm.fullname,
  isClosed: contactUsForm.isClosed,
  message: contactUsForm.message,
  phoneNumber: contactUsForm.phoneNumber,
  subject: contactUsForm.subject,
});

function useContactUsFormUpdateForm(
  contactUsForm: ContactUsFormDetailsFragment,
  onSubmit: (data: ContactUsFormUpdateData) => Promise<any[]>,
  disabled: boolean,
): UseContactUsFormDetailsUpdateFormResult {
  const {
    handleChange,
    data: formData,
    formId,
    setIsSubmitDisabled,
  } = useForm(getInitialData(contactUsForm), undefined, {
    confirmLeave: true,
  });

  const handleFormSubmit = useHandleFormSubmit({
    formId,
    onSubmit,
  });

  const { setExitDialogSubmitRef } = useExitFormDialog({
    formId,
  });

  const data: ContactUsFormUpdateData = {
    ...formData,
  };

  const getData = async (): Promise<ContactUsFormUpdateData> => ({
    ...formData,
  });

  const getSubmitData = async (): Promise<ContactUsFormUpdateData> => ({
    ...(await getData()),
  });

  const submit = async () => handleFormSubmit(await getSubmitData());
  useEffect(() => setExitDialogSubmitRef(submit), [submit]);
  setIsSubmitDisabled(disabled);

  return {
    change: handleChange,
    data,
    submit,
    isSaveDisabled: disabled,
  };
}

const ContactUsFormUpdateForm: React.FC<ContactUsFormUpdateFormProps> = ({
  children,
  contactUsForm,
  onSubmit,
  disabled,
}) => {
  const { ...props } = useContactUsFormUpdateForm(
    contactUsForm,
    onSubmit,
    disabled,
  );
  return <form onSubmit={props.submit}>{children(props)}</form>;
};

ContactUsFormUpdateForm.displayName = "ContactUsFormUpdateForm";
export default ContactUsFormUpdateForm;
