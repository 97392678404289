import PreviewPill from "@dashboard/components/PreviewPill";
import { Box, Checkbox, Text } from "@saleor/macaw-ui/next";
import React from "react";
import { FormattedMessage } from "react-intl";

import { messages } from "./messages";

interface IsShopAndGoProps {
  isChecked: boolean;
}

export const IsShopAndGo = ({ isChecked }: IsShopAndGoProps) => (
  <Box paddingX={6}>
    <Checkbox
      name="isShopAndGo"
      data-test-id="is-channel-shop-and-go-checkbox"
      checked={isChecked}
      disabled
    >
      <Text>
        <FormattedMessage {...messages.isChannelShopAndGoLabel} />
      </Text>
      <PreviewPill />
    </Checkbox>
    <Box paddingLeft={4}>
      <Text
        variant="caption"
        color="textNeutralSubdued"
        size="large"
        paddingLeft={0.5}
      >
        <FormattedMessage {...messages.isChannelShopAndGoDescription} />
      </Text>
    </Box>
  </Box>
);
