import React from "react";
import { useIntl } from "react-intl";
import { sectionNames } from "@dashboard/intl";
import { WindowTitle } from "../components/WindowTitle";
import { parse as parseQs } from "qs";
import ShopAndGoOrderListComponent from "./views/ShopAndGoList";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import {
  ShopAndGoOrderListUrlQueryParams,
  ShopAndGoOrderListUrlSortField,
  ShopAndGoOrderUrlQueryParams,
  shopAndGoListPath,
  shopAndGoOrderPath,
} from "./urls";
import { asSortParams } from "@dashboard/utils/sort";
import ShopAndGoOrderDetailsView from "./views/ShopAndGoOrderDetails";

interface ShopAndGoOrderDetailsRouteParams {
  id: string;
}

const ShopAndGoOrderDetails: React.FC<
  RouteComponentProps<ShopAndGoOrderDetailsRouteParams>
> = ({ location, match }) => {
  const qs = parseQs(location.search.substr(1));
  const params: ShopAndGoOrderUrlQueryParams = qs;
  return (
    <ShopAndGoOrderDetailsView
      id={decodeURIComponent(match.params.id)}
      params={params}
    />
  );
};

const ShopAndGoOrderList: React.FC<RouteComponentProps<any>> = ({
  location,
}) => {
  const qs = parseQs(location.search.substr(1)) as any;
  const params: ShopAndGoOrderListUrlQueryParams = asSortParams(
    qs,
    ShopAndGoOrderListUrlSortField,
    ShopAndGoOrderListUrlSortField.dateCreated,
    false,
  );
  return <ShopAndGoOrderListComponent params={params} />;
};

const Component = () => {
  const intl = useIntl();
  return (
    <>
      <WindowTitle title={intl.formatMessage(sectionNames.shopAndGoOrders)} />
      <Switch>
        <Route exact path={shopAndGoListPath} component={ShopAndGoOrderList} />
        <Route
          path={shopAndGoOrderPath(":id")}
          component={ShopAndGoOrderDetails}
        />
      </Switch>
    </>
  );
};

export default Component;
