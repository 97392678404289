import React from "react";
import {
  ShopAndGoOrderPageTab,
  ShopAndGoOrderUpdatePage,
} from "../components/ShopAndGoUpdatePage/ShopAndGoUpdatePage";
import { ShopAndGoOrderUrlQueryParams, shopAndGoOrderListUrl } from "../urls";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { useIntl } from "react-intl";
import {
  ShopAndGoOrderUpdateMutation,
  useShopAndGoOrderDetailsQuery,
  useShopAndGoOrderUpdateMutation,
} from "@dashboard/graphql";
import { commonMessages } from "@dashboard/intl";
import { ShopAndGoUpdateFormData } from "../components/ShopAndGoUpdatePage/form";
import { extractMutationErrors, maybe } from "@dashboard/misc";
import NotFoundPage from "@dashboard/components/NotFoundPage";
import { WindowTitle } from "@dashboard/components/WindowTitle";

export interface ShopAndGoOrderDetailsProps {
  params: ShopAndGoOrderUrlQueryParams;
  id: string;
}

export function getActiveTab(tabName: string): ShopAndGoOrderPageTab | string {
  return tabName === ShopAndGoOrderPageTab.shopAndGoOrder
    ? ShopAndGoOrderPageTab.shopAndGoOrder
    : "Shop and Go Order";
}

export const ShopAndGoOrderDetails: React.FC<ShopAndGoOrderDetailsProps> = ({
  id,
}) => {
  const { data, loading } = useShopAndGoOrderDetailsQuery({
    displayLoader: true,
    variables: { id },
  });

  const navigate = useNavigator();
  const notify = useNotifier();
  const intl = useIntl();

  const shopAndGoOrder = data?.shopAndGoOrder;
  const handleShopAndGoOrderUpdate = (data: ShopAndGoOrderUpdateMutation) => {
    if (data.shopAndGoOrderUpdate.errors.length > 0) {
      data.shopAndGoOrderUpdate.errors.map(error => {
        notify({
          status: "error",
          title: error.code,
          text: error.message,
        });
      });
    } else
      notify({
        status: "success",
        text: intl.formatMessage(commonMessages.savedChanges),
      });
  };

  const [updateShopAndGoOrder, updateResult] = useShopAndGoOrderUpdateMutation({
    onCompleted: handleShopAndGoOrderUpdate,
  });

  const handleUpdate = async (formData: ShopAndGoUpdateFormData) =>
    extractMutationErrors(
      updateShopAndGoOrder({
        variables: {
          id,
          input: {
            isClosed: formData.isClosed,
          },
        },
      }),
    );

  if (shopAndGoOrder === null) {
    return <NotFoundPage onBack={() => navigate(shopAndGoOrderListUrl())} />;
  }

  return (
    <>
      {" "}
      {!loading && (
        <>
          <WindowTitle
            title={maybe(() => "Order #" + data.shopAndGoOrder.id)}
          />
          <ShopAndGoOrderUpdatePage
            shopAndGoOrder={maybe(() => data.shopAndGoOrder)}
            disabled={loading}
            errors={updateResult.data?.shopAndGoOrderUpdate.errors || []}
            onSubmit={handleUpdate}
            saveButtonBarState={updateResult.status}
          />
        </>
      )}
    </>
  );
};

export default ShopAndGoOrderDetails;
