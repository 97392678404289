import { IFilter } from "@dashboard/components/Filter";
import { FilterOpts, MinMax } from "@dashboard/types";
import {
  createBooleanField,
  createDateField,
  createOptionsField,
} from "@dashboard/utils/filters/fields";
import { IntlShape } from "react-intl";
import { messages } from "../ShopAndGoListDatagrid/messages";
import { shopAndGoOrderMessage } from "@dashboard/intl";
import { SingleAutocompleteChoiceType } from "@dashboard/components/SingleAutocompleteSelectField";

export enum ShopAndGoOrderListFilterKeys {
  email = "email",
  fullname = "fullname",
  isClosed = "isClosed",
  selectedStore = "selectedStore",
  dateCreated = "dateCreated",
}

export interface ShopAndGoOrderListFilterOpts {
  isClosed: FilterOpts<boolean>;
  selectedStore: FilterOpts<string[]> & {
    choices: SingleAutocompleteChoiceType[];
  };
  dateCreated: FilterOpts<MinMax>;
}

export function createFilterStructure(
  intl: IntlShape,
  opts: ShopAndGoOrderListFilterOpts,
): IFilter<ShopAndGoOrderListFilterKeys> {
  return [
    {
      ...createDateField(
        ShopAndGoOrderListFilterKeys.dateCreated,
        intl.formatMessage(messages.dateCreated),
        opts.dateCreated.value,
      ),
      active: opts.dateCreated.active,
    },
    {
      ...createBooleanField(
        ShopAndGoOrderListFilterKeys.isClosed,
        intl.formatMessage(messages.isClosed),
        opts.isClosed.value,
        {
          negative: intl.formatMessage(shopAndGoOrderMessage.isClosedFalse),
          positive: intl.formatMessage(shopAndGoOrderMessage.isClosedTrue),
        },
      ),
      active: opts.isClosed.active,
    },
    ...(opts?.selectedStore?.choices?.length
      ? [
          {
            ...createOptionsField(
              ShopAndGoOrderListFilterKeys.selectedStore,
              intl.formatMessage(messages.selectedStore),
              opts.selectedStore.value,
              true,
              opts.selectedStore.choices,
            ),
            active: opts.selectedStore.active,
          },
        ]
      : []),
  ];
}
