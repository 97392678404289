import { ShopAndGoOrdersListQuery } from "@dashboard/graphql";
import { ShopAndGoOrderListUrlSortField } from "@dashboard/shopAndGo/urls";
import { RelayToFlat } from "@dashboard/types";

export function getShopAndGoOrdersLength(
    orders?: RelayToFlat<ShopAndGoOrdersListQuery["shopAndGoOrders"]>,
    loading?: boolean,
  ) {
    if (loading) {
      return 1;
    }
  
    if (orders?.length) {
      return orders.length;
    }
  
    return 0;
  }

export function getColumnNameAndId(column: string): {
columnName: ShopAndGoOrderListUrlSortField;
columnId?: string;
} {
if (column.includes(":")) {
    const [columnName, columnId] = column.split(":");

    return {
    columnName: columnName as ShopAndGoOrderListUrlSortField,
    columnId,
    };
}


return {
    columnName: column as ShopAndGoOrderListUrlSortField,
};
}

export function canBeSorted(sort: ShopAndGoOrderListUrlSortField) {
  switch (sort) {
    case ShopAndGoOrderListUrlSortField.fullname:
    case ShopAndGoOrderListUrlSortField.email:
    case ShopAndGoOrderListUrlSortField.dateCreated:
    case ShopAndGoOrderListUrlSortField.selectedStore:
      return true;
    default:
      return false;
  }
}