import { TopNav } from "@dashboard/components/AppLayout";
import { ListPageLayout } from "@dashboard/components/Layouts";
import { ShopAndGoOrdersListQuery } from "@dashboard/graphql";
import { sectionNames } from "@dashboard/intl";
import {
  ShopAndGoOrderListUrlQueryParams,
  ShopAndGoOrderListUrlSortField,
  shopAndGoOrderUrl,
} from "@dashboard/shopAndGo/urls";
import {
  FilterPageProps,
  PageListProps,
  RelayToFlat,
  SortPage,
} from "@dashboard/types";
import { Card } from "@material-ui/core";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { ShopAndGoOrdersListDatagrid } from "../ShopAndGoListDatagrid";
import {
  ShopAndGoOrderListFilterKeys,
  ShopAndGoOrderListFilterOpts,
  createFilterStructure,
} from "./filters";
import { useUserAccessibleChannels } from "@dashboard/auth/hooks/useUserAccessibleChannels";
import { useDevModeContext } from "@dashboard/components/DevModePanel/hooks";
import { FilterPresetsSelect } from "@dashboard/components/FilterPresetsSelect";
import { shopAndGoMessages } from "@dashboard/shopAndGo/messages";
import { Box, ChevronRightIcon } from "@saleor/macaw-ui/next";
import { ListFilters } from "@dashboard/components/AppLayout/ListFilters";
import { extensionMountPoints } from "@dashboard/apps/hooks/useExtensions";

export interface ShopAndGoOrderListPageProps
  extends PageListProps,
    Omit<
      FilterPageProps<
        ShopAndGoOrderListFilterKeys,
        ShopAndGoOrderListFilterOpts
      >,
      "onTabDelete"
    >,
    SortPage<ShopAndGoOrderListUrlSortField> {
  shopAndGoOrders: RelayToFlat<ShopAndGoOrdersListQuery["shopAndGoOrders"]>;
  hasPresetsChanged: boolean;
  onSettingsOpen: () => void;
  onAdd: () => void;
  params: ShopAndGoOrderListUrlQueryParams;
  onTabUpdate: (tabName: string) => void;
  onTabDelete: (tabIndex: number) => void;
}

const ShopAndGoOrderListPage: React.FC<ShopAndGoOrderListPageProps> = ({
  initialSearch,
  filterOpts,
  onAdd,
  onSearchChange,
  onSettingsOpen,
  onFilterChange,
  params,
  onTabChange,
  onTabDelete,
  onTabSave,
  onTabUpdate,
  tabs,
  onAll,
  currentTab,
  hasPresetsChanged,
  ...listProps
}) => {
  const intl = useIntl();
  const userAccessibleChannels = useUserAccessibleChannels();
  const hasAccessibleChannels = userAccessibleChannels.length > 0;
  const filterStructure = createFilterStructure(intl, filterOpts);
  const [isFilterPresetOpen, setFilterPresetOpen] = useState(false);

  return (
    <ListPageLayout>
      <TopNav
        title={intl.formatMessage(sectionNames.shopAndGoOrders)}
        withoutBorder
        isAlignToRight={false}
      >
        <Box
          __flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box marginX={3} display="flex" alignItems="center">
              <ChevronRightIcon />
            </Box>

            <FilterPresetsSelect
              presetsChanged={hasPresetsChanged}
              onSelect={onTabChange}
              onRemove={onTabDelete}
              onUpdate={onTabUpdate}
              savedPresets={tabs}
              activePreset={currentTab}
              onSelectAll={onAll}
              onSave={onTabSave}
              isOpen={isFilterPresetOpen}
              onOpenChange={setFilterPresetOpen}
              selectAllLabel={intl.formatMessage(
                shopAndGoMessages.filterPresetsAll,
              )}
            />
          </Box>
        </Box>
      </TopNav>
      <Card>
        <ListFilters
          initialSearch={initialSearch}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          filterStructure={filterStructure}
          searchPlaceholder={intl.formatMessage({
            id: "Lf92jq",
            defaultMessage: "Search Shop And Go Orders...",
          })}
        />
        <ShopAndGoOrdersListDatagrid
          {...listProps}
          hasRowHover={!isFilterPresetOpen}
          rowAnchor={shopAndGoOrderUrl}
        />
      </Card>
    </ListPageLayout>
  );
};

ShopAndGoOrderListPage.displayName = "ShopAndGoOrderListPage";
export default ShopAndGoOrderListPage;
