import Delimiter from "@editorjs/delimiter";
import EditorJS, {
  EditorConfig,
  OutputData,
  ToolConstructable,
} from "@editorjs/editorjs";
import ImageTool from "@editorjs/image";
import Paragraph from "@editorjs/paragraph";
import RawTool from "@editorjs/raw";
import Table from "@editorjs/table";
import {
  EditorCore,
  Props as ReactEditorJSProps,
  ReactEditorJS as BaseReactEditorJS,
} from "@react-editor-js/core";
import React from "react";

// Source of @react-editor-js
class ClientEditorCore implements EditorCore {
  private readonly _editorJS: EditorJS;

  constructor({ tools, ...config }: EditorConfig, fileUploadMutation: any) {
    const extendTools = {
      // default tools
      paragraph: {
        class: Paragraph,
        inlineToolbar: true,
      } as unknown as ToolConstructable,
      raw: RawTool,
      delimiter: Delimiter,
      table: Table,
      ...tools,
      image: {
        class: ImageTool,
        config: {
          uploader: {
            async uploadByFile(file: File) {
              const res = await fileUploadMutation({
                variables: {
                  file,
                },
              });
              const {
                data: {
                  fileUpload: {
                    uploadedFile: { url },
                  },
                },
              } = res;
              return {
                success: 1,
                file: {
                  url,
                },
              };
            },
          },
        },
      },
    };

    this._editorJS = new EditorJS({
      tools: extendTools,
      ...config,
    });
  }

  public async clear() {
    await this._editorJS.clear();
  }

  public async save() {
    return this._editorJS.save();
  }

  public async destroy() {
    try {
      await this._editorJS.destroy();
    } catch (e) {
      /*
        Dismiss that error.
        Sometimes instance is already unmounted while Editor wants to destroy it.
        Editorjs does this properly so this error does not break anything
       */
    }
  }

  public async render(data: OutputData) {
    await this._editorJS.render(data);
  }
}

export type Props = Omit<ReactEditorJSProps, "factory"> & {
  fileUploadMutation: any;
};

function ReactEditorJSClient(props: Props) {
  const { fileUploadMutation } = props;
  const factory = React.useCallback(
    (config: EditorConfig) => new ClientEditorCore(config, fileUploadMutation),
    [],
  );

  return <BaseReactEditorJS factory={factory} {...props} />;
}

export const ReactEditorJS = ReactEditorJSClient;
