import { FilterElement, FilterElementRegular } from "@dashboard/components/Filter";
import {
  ContactUsFormFilterKeys,
  ContactUsFormListFilterOpts,
} from "@dashboard/contactUsForms/components/ContactUsFormListPage/filters";
import {
  ContactUsFormListFitersWithMultipleValues,
  ContactUsFormListUrlFilters,
  ContactUsFormListUrlFiltersEnum,
  ContactUsFormListUrlQueryParams,
} from "@dashboard/contactUsForms/urls";
import { ContactUsFormFilterInput, ContactUsFormSubjectFilter } from "@dashboard/graphql";
import { findInEnum, findValueInEnum, parseBoolean } from "@dashboard/misc";
import {
  createFilterTabUtils,
  createFilterUtils,
  dedupeFilter,
  getGteLteVariables,
  getMinMaxQueryParam,
  getMultipleEnumValueQueryParam,
  getSingleValueQueryParam,
} from "@dashboard/utils/filters";

export const CONTACT_US_FORMS_LIST_FILTERS_KEY =
  "shopAndGoOrderListFiltersPresets";

export function getFilterOpts(
  params: ContactUsFormListUrlFilters,
): ContactUsFormListFilterOpts {
  return {
    isClosed: {
      active: params?.isClosed !== undefined,
      value: parseBoolean(params?.isClosed, false),
    },
    dateCreated: {
      active: [params?.createdFrom, params?.createdTo].some(
        field => field !== undefined,
      ),
      value: {
        max: params?.createdTo || "",
        min: params?.createdFrom || "",
      },
    },
    subject: {
      active: params?.subject !== undefined,
      value: dedupeFilter(
        params.subject?.map(subject => 
          findValueInEnum(subject, ContactUsFormSubjectFilter),
          ) || [],      
      ),
    }
  };
}

export function getFilterQueryParam(
  filter: FilterElement<ContactUsFormFilterKeys>,
): ContactUsFormListUrlFilters {
  const { name } = filter;

  switch (name) {
    case ContactUsFormFilterKeys.isClosed:
      return getSingleValueQueryParam(
        filter,
        ContactUsFormListUrlFiltersEnum.isClosed,
      );

    case ContactUsFormFilterKeys.dateCreated:
      return getMinMaxQueryParam(
        filter,
        ContactUsFormListUrlFiltersEnum.createdFrom,
        ContactUsFormListUrlFiltersEnum.createdTo,
      );
    
    case ContactUsFormFilterKeys.subject:
      return getMultipleEnumValueQueryParam(
        filter as FilterElementRegular<ContactUsFormFilterKeys.subject>,
        ContactUsFormListFitersWithMultipleValues.subject,
        ContactUsFormSubjectFilter
      );
  }
}

export const storageUtils = createFilterTabUtils<string>(
  CONTACT_US_FORMS_LIST_FILTERS_KEY,
);
export const { areFiltersApplied, getActiveFilters, getFiltersCurrentTab } =
  createFilterUtils<
    ContactUsFormListUrlQueryParams,
    ContactUsFormListUrlFilters
  >({
    ...ContactUsFormListUrlFiltersEnum,
    ...ContactUsFormListFitersWithMultipleValues,
  });

export function getFilterVariables(
  params: ContactUsFormListUrlFilters,
): ContactUsFormFilterInput {
  return {
    created: getGteLteVariables({
      gte: params.createdFrom,
      lte: params.createdTo,
    }),
    isClosed:
      params.isClosed !== undefined
        ? parseBoolean(params.isClosed, false)
        : undefined,
    search: params.query,
    subject: params?.subject?.map(subject => findInEnum(subject, ContactUsFormSubjectFilter))

  };
}
