// @ts-strict-ignore
import { handleNestedMutationErrors } from "@dashboard/auth";
import { formatMoney } from "@dashboard/components/Money";
import messages from "@dashboard/containers/BackgroundTasks/messages";
import {
  CountryCode,
  CreateManualTransactionCaptureMutation,
  InvoiceEmailSendMutation,
  InvoiceRequestMutation,
  PreparationSlipRequestMutation,
  OrderAddNoteMutation,
  OrderCancelMutation,
  OrderCaptureMutation,
  OrderDraftCancelMutation,
  OrderDraftFinalizeMutation,
  OrderDraftUpdateMutation,
  OrderErrorCode,
  OrderFulfillmentApproveMutation,
  OrderFulfillmentCancelMutation,
  OrderFulfillmentUpdateTrackingMutation,
  OrderLineDeleteMutation,
  OrderLinesAddMutation,
  OrderLineUpdateMutation,
  OrderMarkAsPaidMutation,
  OrderShippingMethodUpdateMutation,
  OrderTransactionRequestActionMutation,
  OrderUpdateMutation,
  OrderVoidMutation,
  useCheckoutCompleteMutation,
  useCheckoutCreateMutation,
  useCheckoutDeliveryMethodUpdateMutation,
  useCheckoutPaymentCreateMutation,
} from "@dashboard/graphql";
import useLocale from "@dashboard/hooks/useLocale";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import getOrderErrorMessage from "@dashboard/utils/errors/order";
import {
  getOrderTransactionErrorMessage,
  getTransactionCreateErrorMessage,
  transactionCreateMessages,
  transactionRequestMessages as transactionMessages,
} from "@dashboard/utils/errors/transaction";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import React from "react";
import { useIntl } from "react-intl";

import { orderUrl, OrderUrlQueryParams } from "../../urls";
import { PAYMENT_GATEWAY_ID } from "@dashboard/config";

interface OrderDetailsMessages {
  children: (props: {
    handleDraftCancel: (data: OrderDraftCancelMutation) => void;
    handleDraftFinalize: (data: OrderDraftFinalizeMutation) => void;
    handleDraftUpdate: (data: OrderDraftUpdateMutation) => void;
    handleNoteAdd: (data: OrderAddNoteMutation) => void;
    handleOrderCancel: (data: OrderCancelMutation) => void;
    handleOrderFulfillmentApprove: (
      data: OrderFulfillmentApproveMutation,
    ) => void;
    handleOrderFulfillmentCancel: (
      data: OrderFulfillmentCancelMutation,
    ) => void;
    handleOrderFulfillmentUpdate: (
      data: OrderFulfillmentUpdateTrackingMutation,
    ) => void;
    handleOrderLinesAdd: (data: OrderLinesAddMutation) => void;
    handleOrderLineDelete: (data: OrderLineDeleteMutation) => void;
    handleOrderLineUpdate: (data: OrderLineUpdateMutation) => void;
    handleOrderMarkAsPaid: (data: OrderMarkAsPaidMutation) => void;
    handleOrderVoid: (data: OrderVoidMutation) => void;
    handlePaymentCapture: (data: OrderCaptureMutation) => void;
    handleShippingMethodUpdate: (
      data: OrderShippingMethodUpdateMutation,
    ) => void;
    handleUpdate: (data: OrderUpdateMutation) => void;
    handleInvoiceGeneratePending: (data: InvoiceRequestMutation) => void;
    handleInvoiceGenerateFinished: (data: InvoiceRequestMutation) => void;
    handlePreparationSlipGeneratePending: (data: PreparationSlipRequestMutation) => void;
    handlePreparationSlipGenerateFinished: (data: PreparationSlipRequestMutation) => void;
    handleInvoiceSend: (data: InvoiceEmailSendMutation) => void;
    handleTransactionAction: (
      data: OrderTransactionRequestActionMutation,
    ) => void;
    handleAddManualTransaction: (
      data: CreateManualTransactionCaptureMutation,
    ) => void;
  }) => React.ReactElement;
  id: string;
  params: OrderUrlQueryParams;
}

export const OrderDetailsMessages: React.FC<OrderDetailsMessages> = ({
  children,
  id,
  params,
}) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const intl = useIntl();
  const { locale } = useLocale();

  const [, closeModal] = createDialogActionHandlers(
    navigate,
    params => orderUrl(id, params),
    params,
  );

  const [checkoutCreate] = useCheckoutCreateMutation();
  const [checkoutDeliveryMethodUpdate] =
    useCheckoutDeliveryMethodUpdateMutation();
  const [checkoutPaymentCreate] = useCheckoutPaymentCreateMutation();
  const [checkoutComplete] = useCheckoutCompleteMutation();

  const handlePaymentCapture = (data: OrderCaptureMutation) => {
    const errs = data.orderCapture?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "9RCuN3",
          defaultMessage: "Payment successfully captured",
        }),
      });
      closeModal();
    }
  };
  const handleOrderMarkAsPaid = (data: OrderMarkAsPaidMutation) => {
    const errs = data.orderMarkAsPaid?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "lL1HTg",
          defaultMessage: "Order marked as paid",
        }),
      });
      closeModal();
    }
  };
  const handleOrderCancel = (data: OrderCancelMutation) => {
    const errs = data.orderCancel?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "W/Es0H",
          defaultMessage: "Order successfully cancelled",
        }),
      });
      closeModal();
    }
  };
  const handleDraftCancel = (data: OrderDraftCancelMutation) => {
    const errs = data.draftOrderDelete?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "W/Es0H",
          defaultMessage: "Order successfully cancelled",
        }),
      });
      closeModal();
    }
  };
  const handleOrderVoid = (data: OrderVoidMutation) => {
    const errs = data.orderVoid?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "L87bp7",
          defaultMessage: "Order payment successfully voided",
        }),
      });
      closeModal();
    }
  };
  const handleNoteAdd = (data: OrderAddNoteMutation) => {
    const errs = data.orderAddNote?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "KmPicj",
          defaultMessage: "Note successfully added",
        }),
      });
    }
  };
  const handleUpdate = (data: OrderUpdateMutation) => {
    const errs = data.orderUpdate?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "j2fPVo",
          defaultMessage: "Order successfully updated",
        }),
      });
      closeModal();
    }
  };
  const handleDraftUpdate = (data: OrderDraftUpdateMutation) => {
    const errs = data.draftOrderUpdate?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "j2fPVo",
          defaultMessage: "Order successfully updated",
        }),
      });
      closeModal();
    }
  };
  const handleShippingMethodUpdate = (
    data: OrderShippingMethodUpdateMutation,
  ) => {
    const errs = data.orderUpdateShipping?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "7U8GRy",
          defaultMessage: "Shipping method successfully updated",
        }),
      });
      closeModal();
    }
  };
  const handleOrderLineDelete = (data: OrderLineDeleteMutation) => {
    const errs = data.orderLineDelete?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "9OtpHt",
          defaultMessage: "Order line deleted",
        }),
      });
    }
  };
  const handleOrderLinesAdd = (data: OrderLinesAddMutation) => {
    const errs = data.orderLinesCreate?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "HlCkMT",
          defaultMessage: "Order line added",
        }),
      });
      closeModal();
    }
  };
  const handleOrderLineUpdate = (data: OrderLineUpdateMutation) => {
    const errs = data.orderLineUpdate?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "Fn3bE0",
          defaultMessage: "Order line updated",
        }),
      });
    } else {
      errs.forEach(error =>
        notify({
          status: "error",
          text: getOrderErrorMessage(error, intl),
        }),
      );
    }
  };
  const handleOrderFulfillmentApprove = (
    data: OrderFulfillmentApproveMutation,
  ) => {
    const errs = data.orderFulfillmentApprove?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "+sX7yS",
          defaultMessage: "Fulfillment successfully approved",
        }),
      });
      closeModal();
    } else {
      if (!errs.every(err => err.code === OrderErrorCode.INSUFFICIENT_STOCK)) {
        handleNestedMutationErrors({ data, intl, notify });
      }
    }
  };
  const handleOrderFulfillmentCancel = (
    data: OrderFulfillmentCancelMutation,
  ) => {
    const errs = data.orderFulfillmentCancel?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "uMpv1v",
          defaultMessage: "Fulfillment successfully cancelled",
        }),
      });
      closeModal();
    }
  };
  const handleOrderFulfillmentUpdate = (
    data: OrderFulfillmentUpdateTrackingMutation,
  ) => {
    const errs = data.orderFulfillmentUpdateTracking?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "CZmloB",
          defaultMessage: "Fulfillment successfully updated",
        }),
      });
      closeModal();
    }
  };
  const handleDraftFinalize = async (data: OrderDraftFinalizeMutation) => {
    const errs = data.draftOrderComplete?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage({
          id: "c4gbXr",
          defaultMessage: "Draft order successfully finalized",
        }),
      });
      const checkoutDataInput = data.draftOrderComplete.order;

      const validationRules = {
        billingAddress: {
          checkRequiredFields: true,
        },
        shippingAddress: {
          checkRequiredFields: true,
        },
      };

      const { data: checkoutData, errors: checkoutErrors } =
        await checkoutCreate({
          variables: {
            input: {
              lines: checkoutDataInput.lines.map(line => ({
                quantity: line?.quantity,
                variantId: line?.variant?.id,
              })),
              email: checkoutDataInput.userEmail,
              channel: checkoutDataInput.channel.slug,
              billingAddress: {
                firstName: checkoutDataInput.billingAddress.firstName,
                lastName: checkoutDataInput.billingAddress.lastName,
                city: checkoutDataInput.billingAddress.city,
                country: CountryCode.PH,
                postalCode: checkoutDataInput.billingAddress.postalCode,
                streetAddress1: checkoutDataInput.billingAddress.streetAddress1,
                streetAddress2: checkoutDataInput.billingAddress.streetAddress2,
                countryArea: checkoutDataInput.billingAddress.countryArea,
              },
              shippingAddress: {
                firstName: checkoutDataInput.shippingAddress.firstName,
                lastName: checkoutDataInput.shippingAddress.lastName,
                city: checkoutDataInput.shippingAddress.city,
                country: CountryCode.PH,
                postalCode: checkoutDataInput.shippingAddress.postalCode,
                streetAddress1:
                  checkoutDataInput.shippingAddress.streetAddress1,
              },
              validationRules: validationRules,
            },
          },
        });

      if (checkoutErrors?.length) {
        console.error("Checkout creation errors:", checkoutErrors);
        notify({
          status: "error",
          text: intl.formatMessage({
            id: "checkoutError",
            defaultMessage: "Error creating checkout from order",
          }),
        });
        return;
      }

      const { checkout } = checkoutData.checkoutCreate;

      // Find a valid warehouse ID for delivery method
      const lineWithValidId = checkout.lines.find(
        line =>
          line.variant.stocks &&
          line.variant.stocks.length > 0 &&
          line.variant.stocks[0].warehouse &&
          line.variant.stocks[0].warehouse.id.length > 0,
      );

      const deliveryMethodWarehouseId = lineWithValidId
        ? lineWithValidId.variant.stocks[0].warehouse.id
        : "";

      // Extracting gateway and checkoutId and checkoutToken
      const { token: checkoutToken, id: checkoutId } = checkout;

      const {
        data: checkoutDeliveryMethodData,
        errors: checkoutDeliveryMethodErrors,
      } = await checkoutDeliveryMethodUpdate({
        variables: {
          deliveryMethodId: deliveryMethodWarehouseId,
          token: checkoutToken,
        },
      });

      if (checkoutDeliveryMethodErrors?.length) {
        console.error(
          "Checkout delivery method errors:",
          checkoutDeliveryMethodErrors,
        );
        notify({
          status: "error",
          text: intl.formatMessage({
            id: "checkoutDeliveryMethodError",
            defaultMessage: "Error creating checkout delivery method",
          }),
        });
        return;
      }

      const gatewayDetails = checkout.availablePaymentGateways.map(
        (gateway, id) => ({
          id: gateway.id,
          name: gateway.name,
        }),
      );

      const selectedGateway = gatewayDetails.find(
        gateway => gateway.id === PAYMENT_GATEWAY_ID,
      );

      if (!selectedGateway) {
        console.error("Gateway ID not found");
        return;
      }

      const { data: checkoutPaymentData, errors: checkoutPaymentErrors } =
        await checkoutPaymentCreate({
          variables: { gateway: selectedGateway.id, token: checkoutToken },
        });

      if (checkoutPaymentErrors?.length) {
        console.error("Checkout payment errors:", checkoutPaymentErrors);
        notify({
          status: "error",
          text: intl.formatMessage({
            id: "checkoutPaymentError",
            defaultMessage: "Error creating checkout payment",
          }),
        });
        return;
      }

      const orderIdData = JSON.stringify({
        order_id: checkoutDataInput.id,
      });

      const { data: checkoutCompleteData, errors: checkoutCompleteErrors } =
        await checkoutComplete({
          variables: { token: checkoutToken, paymentData: orderIdData },
        });

      if (checkoutCompleteErrors?.length) {
        console.error("Checkout complete errors:", checkoutCompleteErrors);
        notify({
          status: "error",
          text: intl.formatMessage({
            id: "checkoutCompleteError",
            defaultMessage: "Error completing the checkout",
          }),
        });
        return;
      }

      notify({
        status: "success",
        text: intl.formatMessage({
          id: "checkoutSuccess",
          defaultMessage: "Checkout successfully created from order",
        }),
      });
    } else {
      notify({
        status: "error",
        text: intl.formatMessage({
          id: "draftFinalizeError",
          defaultMessage: "Error finalizing draft order",
        }),
      });
    }
  };
  const handleInvoiceGeneratePending = (data: InvoiceRequestMutation) => {
    const errs = data.invoiceRequest?.errors;
    if (errs.length === 0) {
      notify({
        text: intl.formatMessage({
          id: "ND5x+V",
          defaultMessage:
            "We’re generating the invoice you requested. Please wait a couple of moments",
        }),
        title: intl.formatMessage({
          id: "PKJqcq",
          defaultMessage: "Invoice is Generating",
        }),
      });
      closeModal();
    }
  };
  const handleInvoiceGenerateFinished = (data: InvoiceRequestMutation) => {
    const errs = data.invoiceRequest?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: intl.formatMessage(messages.invoiceGenerateFinishedText),
        title: intl.formatMessage(messages.invoiceGenerateFinishedTitle),
      });
      closeModal();
    }
  };
  const handlePreparationSlipGeneratePending = (data: PreparationSlipRequestMutation) => {
    const errs = data.preparationSlip?.errors;
    if (errs.length === 0) {
      notify({
        text: intl.formatMessage({
          id: "ND5x+V",
          defaultMessage:
            "We’re generating the preparation slip you requested. Please wait a couple of moments",
        }),
        title: intl.formatMessage({
          id: "PKJqcq",
          defaultMessage: "Preparation Slip is Generating",
        }),
      });
      closeModal();
    }
  };
  const handlePreparationSlipGenerateFinished = (data: PreparationSlipRequestMutation) => {
    const errs = data.preparationSlip?.errors;
    if (errs.length === 0) {
      notify({
        status: "success",
        text: "The preparation slip you requested has been successfully generated.",
        title: "Preparation Slip Generated",
      });
      closeModal();
    }
  };
  const handleInvoiceSend = (data: InvoiceEmailSendMutation) => {
    const errs = data.invoiceSendNotification?.errors;
    if (errs.length === 0) {
      notify({
        text: intl.formatMessage({
          id: "3u+4NZ",
          defaultMessage: "Invoice email sent",
        }),
      });
      closeModal();
    }
  };
  const handleTransactionAction = (
    data: OrderTransactionRequestActionMutation,
  ) => {
    const {
      transactionRequestAction: { errors },
    } = data;
    const isError = !!errors.length;

    if (isError) {
      notify({
        status: "error",
        text: getOrderTransactionErrorMessage(errors[0], intl),
      });
    } else {
      notify({
        status: "success",
        text: intl.formatMessage(transactionMessages.success),
      });
      closeModal();
    }
  };
  const handleAddManualTransaction = (
    data: CreateManualTransactionCaptureMutation,
  ) => {
    const {
      transactionCreate: { errors, transaction },
    } = data;
    const isError = !!errors.length;

    if (!isError) {
      notify({
        status: "success",
        text: intl.formatMessage(transactionCreateMessages.success, {
          amount: formatMoney(transaction.chargedAmount, locale),
        }),
      });
      closeModal();
    } else {
      notify({
        status: "error",
        text: getTransactionCreateErrorMessage(errors[0], intl),
      });
    }
  };

  return children({
    handleDraftCancel,
    handleDraftFinalize,
    handleDraftUpdate,
    handleInvoiceGenerateFinished,
    handleInvoiceGeneratePending,
    handlePreparationSlipGeneratePending,
    handlePreparationSlipGenerateFinished,
    handleInvoiceSend,
    handleNoteAdd,
    handleOrderCancel,
    handleOrderFulfillmentApprove,
    handleOrderFulfillmentCancel,
    handleOrderFulfillmentUpdate,
    handleOrderLineDelete,
    handleOrderLineUpdate,
    handleOrderLinesAdd,
    handleOrderMarkAsPaid,
    handleOrderVoid,
    handlePaymentCapture,
    handleShippingMethodUpdate,
    handleUpdate,
    handleTransactionAction,
    handleAddManualTransaction,
  });
};
