import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emptyText: {
    id: "RCiFFH",
    defaultMessage: "No Shop and Go Orders found",
  },
  shopAndGoOrders: {
    id: "S9G9+4",
    defaultMessage: "Shop and Go Orders",
  },
  email: {
    id: "2AcDS6",
    defaultMessage: "Customer Email",
  },
  fullName: {
    id: "x2Dc9G",
    defaultMessage: "Customer Full Name",
  },
  isClosed: {
    id: "7Kwr2C",
    defaultMessage: "Order Is Closed status",
  },
  selectedStore: {
    id: "Td4k2y",
    defaultMessage: "Customer Selected Store Slug",
  },
  dateCreated: {
    id: "8BdEr3",
    defaultMessage: "Order Date Created",
  },
  orderQuery: {
    id: "Ej9n2D",
    defaultMessage: "Order Query",
  }
});

export const columnsMessages = defineMessages({
  fullName: {
    id: "OOR9ZM",
    defaultMessage: "Full Name",
    description: "customer full name",
  },
  phoneNumber: {
    id:"jA91LM",
    defaultMessage: "Phone Number",
    description: "customer phone number",
  },
  orderQuery: {
    id:"RmPf81",
    defaultMessage: "Order Query",
    description: "customer order query",
  },
  isClosed: {
    id:"Lo1Pb8",
    defaultMessage: "Is Closed",
    description: "order is closed",
  },
  selectedStore:{
    id:"8TmyA3k",
    defaultMessage: "Selected Store",
    description: "order selected store",
  },
  email: {
    id: "WcoDyx",
    defaultMessage: "Email",
    description: "customer email",
  },
  dateCreated: {
    id: "1VafIs",
    defaultMessage: "Date Created",
    description: "shop and go form created at",
  },
});

export const categoryMetaGroups = defineMessages({
  attribute: {
    id: "ZfDKTq",
    defaultMessage: "Attributes",
    description: "shop and go order dynamic column description",
  },
});
