import { ShopAndGoOrderErrorFragment } from "@dashboard/graphql";
import { ShopAndGoUpdateFormData } from "../ShopAndGoUpdatePage/form";
import React from "react";
import { useIntl } from "react-intl";
import {
  Card,
  CardContent,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CardTitle from "@dashboard/components/CardTitle";
import { commonMessages } from "@dashboard/intl";
import FormSpacer from "@dashboard/components/FormSpacer";

interface ShopAndGoOrderDetailsFormProps {
  data: ShopAndGoUpdateFormData;
  disabled: boolean;
  errors: ShopAndGoOrderErrorFragment[];
  onChange: (event: React.ChangeEvent<any>) => void;
}

export const ShopAndGoOrderDetailsForm: React.FC<
  ShopAndGoOrderDetailsFormProps
> = ({ data, onChange }) => {
  const intl = useIntl();
  return (
    <Card>
      <CardTitle
        title={intl.formatMessage(commonMessages.generalInformations)}
      />
      <CardContent>
        <div>
          <TextField
            label="Email"
            name="email"
            value={data && data.email}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <TextField
            label="Full Name"
            name="fullname"
            value={data && data.fullname}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={data && data.phoneNumber}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <TextField
            label="Selected Store"
            name="selectedStore"
            value={data && data.selectedStore.slug}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <TextField
            label="Order Query"
            name="orderQuery"
            multiline
            value={data && data.orderQuery}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </div>
        <FormSpacer />
        <div>
          <Select
            name="isClosed"
            value={data && data.isClosed}
            label="Is Closed"
            fullWidth
            onChange={onChange}
          >
            <MenuItem value={false as any}>Open</MenuItem>
            <MenuItem value={true as any}>Closed</MenuItem>
          </Select>
        </div>
        <FormSpacer />
      </CardContent>
    </Card>
  );
};

export default ShopAndGoOrderDetailsForm;
