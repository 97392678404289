// @ts-strict-ignore
import strikethroughIcon from "@dashboard/icons/StrikethroughIcon";
import { ToolConstructable, ToolSettings } from "@editorjs/editorjs";
import AttachesTool from "@editorjs/attaches";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import createGenericInlineTool from "editorjs-inline-tool";
import { createFormData, isValidPdfFile } from "./utils";
import { CONTENT_PAGES_BEARER_TOKEN } from "@dashboard/config";

const inlineToolbar = ["link", "bold", "italic", "strikethrough"];

export const tools: Record<string, ToolConstructable | ToolSettings> = {
  embed: Embed,
  header: {
    class: Header,
    config: {
      defaultLevel: 1,
      levels: [1, 2, 3],
    },
    inlineToolbar,
  },
  list: {
    class: List,
    inlineToolbar,
  },
  quote: {
    class: Quote,
    inlineToolbar,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar,
  } as unknown as ToolConstructable,
  image: {
    class: ImageTool,
    inlineToolbar,
    config: {
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        async uploadByFile(file) {
          try {
            const contentPagesBearerToken = CONTENT_PAGES_BEARER_TOKEN || "";
            const backendUrl = process.env.API_URI || "";

            const formData = createFormData(file);

            const options = {
              method: "POST",
              headers: {
                Authorization: `Bearer ${contentPagesBearerToken}`,
              },
              body: formData,
            };

            // This is needed since the request will override the content type with the appropriate content-type header
            delete options.headers["Content-Type"];

            const response = await fetch(backendUrl, options);
            const responseData = await response.json();

            if (response.ok) {
              const {
                data: { fileUpload },
              }: any = responseData;
              const { uploadedFile } = fileUpload;

              if (uploadedFile) {
                return {
                  success: 1,
                  file: {
                    url: uploadedFile.url,
                  },
                };
              } else {
                console.error("Failed to get uploaded file data");
                return { success: 0 };
              }
            } else {
              // Handle non-OK response
              console.error("Error during file upload:", responseData.errors);
              return { success: 0 };
            }
          } catch (error) {
            // Handle any other errors
            console.error("Error during file upload:", error);
            return { success: 0 };
          }
        },
      },
    },
  },
  attaches: {
    class: AttachesTool,
    inlineToolbar,
    config: {
      uploader: {
        /**
         * Upload PDF file to the server and return an uploaded file data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        async uploadByFile(file) {
          try {
            if (!isValidPdfFile(file, "application/pdf")) {
              console.error("Invalid file type. Only PDF files are allowed");
              return { success: 0 };
            }

            const contentPagesBearerToken = CONTENT_PAGES_BEARER_TOKEN || "";
            const backendUrl = process.env.API_URI || "";

            const formData = createFormData(file);

            const options = {
              method: "POST",
              headers: {
                Authorization: `Bearer ${contentPagesBearerToken}`,
              },
              body: formData,
            };

            // This is needed since the request will override the content type with the appropriate content-type header
            delete options.headers["Content-Type"];

            const response = await fetch(backendUrl, options);
            const responseData = await response.json();

            if (response.ok) {
              const {
                data: { fileUpload },
              }: any = responseData;
              const { uploadedFile } = fileUpload;

              if (uploadedFile) {
                return {
                  success: 1,
                  file: {
                    url: uploadedFile.url,
                  },
                };
              } else {
                console.error("Failed to get uploaded file data");
                return { success: 0 };
              }
            } else {
              // Handle non-OK response
              console.error("Error during file upload:", responseData.errors);
              return { success: 0 };
            }
          } catch (error) {
            // Handle any other errors
            console.error("Error during file upload:", error);
            return { success: 0 };
          }
        },
      },
    },
  },
  raw: Raw,
  strikethrough: createGenericInlineTool({
    sanitize: {
      s: {},
    },
    shortcut: "CMD+S",
    tagName: "s",
    toolboxIcon: strikethroughIcon,
  }),
};
