// @ts-strict-ignore
import { ColumnPicker } from "@dashboard/components/Datagrid/ColumnPicker/ColumnPicker";
import { useColumns } from "@dashboard/components/Datagrid/ColumnPicker/useColumns";
import Datagrid from "@dashboard/components/Datagrid/Datagrid";
import {
  DatagridChangeStateContext,
  useDatagridChangeState,
} from "@dashboard/components/Datagrid/hooks/useDatagridChange";
import { useEmptyColumn } from "@dashboard/components/Datagrid/hooks/useEmptyColumn";
import { TablePaginationWithContext } from "@dashboard/components/TablePagination";
import { ShopAndGoOrdersListQuery } from "@dashboard/graphql";
import { ShopAndGoOrderListUrlSortField } from "@dashboard/shopAndGo/urls";
import { ListProps, RelayToFlat, SortPage } from "@dashboard/types";
import { Item } from "@glideapps/glide-data-grid";
import { Box } from "@saleor/macaw-ui/next";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  shopAndGoOrdersListStaticColumnAdapter,
  useGetCellContent,
} from "./datagrid";
import { messages } from "./messages";
import {
  canBeSorted,
  getColumnNameAndId,
  getShopAndGoOrdersLength,
} from "./utils";

interface ShopAndGoOrderListDatagridProps
  extends ListProps,
    SortPage<ShopAndGoOrderListUrlSortField> {
  shopAndGoOrders: RelayToFlat<ShopAndGoOrdersListQuery["shopAndGoOrders"]>;
  onRowClick?: (id: string) => void;
  rowAnchor?: (id: string) => string;
  hasRowHover?: boolean;
}

export const ShopAndGoOrdersListDatagrid: React.FC<
  ShopAndGoOrderListDatagridProps
> = ({
  shopAndGoOrders,
  disabled,
  settings,
  onUpdateListSettings,
  onSort,
  sort,
  onRowClick,
  hasRowHover,
  rowAnchor,
}) => {
  const intl = useIntl();
  const datagrid = useDatagridChangeState();
  const shopAndGoOrdersLength = getShopAndGoOrdersLength(
    shopAndGoOrders,
    disabled,
  );

  const handleColumnChange = useCallback(
    picked => {
      onUpdateListSettings("columns", picked.filter(Boolean));
    },
    [onUpdateListSettings],
  );

  const emptyColumn = useEmptyColumn();
  const memoizedStaticColumns = useMemo(
    () => shopAndGoOrdersListStaticColumnAdapter(emptyColumn, intl, sort),
    [emptyColumn, intl, sort],
  );

  const { handlers, staticColumns, visibleColumns, selectedColumns } =
    useColumns({
      staticColumns: memoizedStaticColumns,
      selectedColumns: settings?.columns ?? [],
      onSave: handleColumnChange,
    });
  const handleHeaderClick = useCallback(
    (col: number) => {
      const { columnName, columnId } = getColumnNameAndId(
        visibleColumns[col].id,
      );

      if (canBeSorted(columnName)) {
        onSort(columnName, columnId);
      }
    },
    [visibleColumns, onSort],
  );

  const handleRowClick = useCallback(
    ([_, row]: Item) => {
      if (!onRowClick) {
        return;
      }

      const rowData = shopAndGoOrders[row];
      onRowClick(rowData.id);
    },
    [onRowClick, shopAndGoOrders],
  );

  const handleRowAnchor = useCallback(
    ([, row]: Item) => {
      if (!rowAnchor) {
        return;
      }
      const rowData = shopAndGoOrders[row];
      return rowAnchor(rowData.id);
    },
    [rowAnchor, shopAndGoOrders],
  );

  const getCellContent = useGetCellContent({
    columns: visibleColumns,
    shopAndGoOrders,
  });
  return (
    <Box __marginTop={shopAndGoOrdersLength > 0 ? -1 : 0}>
      <DatagridChangeStateContext.Provider value={datagrid}>
        <Datagrid
          readonly
          rowMarkers="none"
          loading={disabled}
          columnSelect="single"
          hasRowHover={hasRowHover}
          freezeColumns={2}
          verticalBorder={col => col > 1}
          availableColumns={visibleColumns}
          onHeaderClicked={handleHeaderClick}
          emptyText={intl.formatMessage(messages.emptyText)}
          getCellContent={getCellContent}
          getCellError={() => false}
          menuItems={() => []}
          rows={getShopAndGoOrdersLength(shopAndGoOrders, disabled)}
          selectionActions={() => null}
          onColumnResize={handlers.onResize}
          onColumnMoved={handlers.onMove}
          renderColumnPicker={() => (
            <ColumnPicker
              staticColumns={staticColumns}
              selectedColumns={selectedColumns}
              onToggle={handlers.onToggle}
            />
          )}
          fullScreenTitle={intl.formatMessage(messages.shopAndGoOrders)}
          onRowClick={handleRowClick}
          rowAnchor={handleRowAnchor}
        />

        <Box paddingX={6}>
          <TablePaginationWithContext
            component="div"
            settings={settings}
            disabled={disabled}
            onUpdateListSettings={onUpdateListSettings}
          />
        </Box>
      </DatagridChangeStateContext.Provider>
    </Box>
  );
};
