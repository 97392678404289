import { useExitFormDialog } from "@dashboard/components/Form";
import { ShopAndGoOrderDetailsFragment } from "@dashboard/graphql";
import useForm, { CommonUseFormResult } from "@dashboard/hooks/useForm";
import useHandleFormSubmit from "@dashboard/hooks/useHandleFormSubmit";
import React, { useEffect } from "react";

export interface ShopAndGoUpdateFormData {
  email: string;
  fullname: string;
  phoneNumber: string;
  selectedStore: {
    __typename: "Channel";
    slug: string;
    isActive: boolean;
    id: string;
  };
  orderQuery: string;
  dateCreated: string;
  isClosed: boolean;
}
export interface ShopAndGoOrderUpdateFormProps {
  children: (
    props: UseShopAndGoOrderDetailsUpdateFormResult,
  ) => React.ReactNode;
  shopAndGoOrder: ShopAndGoOrderDetailsFragment;
  onSubmit: (data: ShopAndGoUpdateFormData) => Promise<any[]>;
  disabled: boolean;
}

export interface UseShopAndGoOrderDetailsUpdateFormResult
  extends CommonUseFormResult<ShopAndGoUpdateFormData> {}

const getInitialData = (shopAndGoOrder: ShopAndGoOrderDetailsFragment) => ({
  email: shopAndGoOrder.email,
  fullname: shopAndGoOrder.fullname,
  phoneNumber: shopAndGoOrder.phoneNumber,
  selectedStore: shopAndGoOrder.selectedStore,
  orderQuery: shopAndGoOrder.orderQuery,
  dateCreated: shopAndGoOrder.dateCreated,
  isClosed: shopAndGoOrder.isClosed,
});

function useShopAndGoOrderUpdateForm(
  shopAndGoOrder: ShopAndGoOrderDetailsFragment,
  onSubmit: (data: ShopAndGoUpdateFormData) => Promise<any[]>,
  disabled: boolean,
): UseShopAndGoOrderDetailsUpdateFormResult {
  const {
    handleChange,
    data: formData,
    formId,
    setIsSubmitDisabled,
  } = useForm(getInitialData(shopAndGoOrder), undefined, {
    confirmLeave: true,
  });

  const handleFormSubmit = useHandleFormSubmit({
    formId,
    onSubmit,
  });

  const { setExitDialogSubmitRef } = useExitFormDialog({
    formId,
  });

  const data: ShopAndGoUpdateFormData = {
    ...formData,
  };

  const getData = async (): Promise<ShopAndGoUpdateFormData> => ({
    ...formData,
  });

  const getSubmitData = async (): Promise<ShopAndGoUpdateFormData> => ({
    ...(await getData()),
  });

  const submit = async () => handleFormSubmit(await getSubmitData());
  useEffect(() => setExitDialogSubmitRef(submit), [submit]);
  setIsSubmitDisabled(disabled);

  return {
    change: handleChange,
    data,
    submit,
    isSaveDisabled: disabled,
  };
}

const ShopAndGoOrderUpdateForm: React.FC<ShopAndGoOrderUpdateFormProps> = ({
  children,
  shopAndGoOrder,
  onSubmit,
  disabled,
}) => {
  const { ...props } = useShopAndGoOrderUpdateForm(
    shopAndGoOrder,
    onSubmit,
    disabled,
  );

  return <form onSubmit={props.submit}>{children(props)}</form>;
};

ShopAndGoOrderUpdateForm.displayName = "ShopAndGoOrderUpdateForm";
export default ShopAndGoOrderUpdateForm;
