// @ts-strict-ignore
import { useFilterPresets } from "@dashboard/hooks/useFilterPresets";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  PaginatorContext,
  createPaginationState,
} from "@dashboard/hooks/usePaginator";
import {
  ShopAndGoOrderListUrlDialog,
  ShopAndGoOrderListUrlQueryParams,
  shopAndGoOrderListUrl,
  shopAndGoOrderSettingsPath,
} from "@dashboard/shopAndGo/urls";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import React from "react";
import { useIntl } from "react-intl";
import {
  storageUtils,
  getFilterQueryParam,
  getFilterOpts,
  getFilterVariables,
} from "./filters";
import { useShopAndGoOrdersListQuery } from "@dashboard/graphql";
import { useFilterHandlers } from "@dashboard/hooks/useFilterHandlers";
import { getSortParams } from "@dashboard/utils/sort";
import { mapEdgesToItems, mapNodeToChoice } from "@dashboard/utils/maps";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { useUser } from "@dashboard/auth";
import { DEFAULT_SORT_KEY, getSortQueryVariables } from "./sort";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import ShopAndGoOrderListPage from "@dashboard/shopAndGo/components/ShopAndGoOrderListPage/ShopAndGoOrderListPage";
import SaveFilterTabDialog from "@dashboard/components/SaveFilterTabDialog";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";

interface ShopAndGoOrderListProps {
  params: ShopAndGoOrderListUrlQueryParams;
}

export const ShopAndGoOrderList: React.FC<ShopAndGoOrderListProps> = ({
  params,
}) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const { updateListSettings, settings } = useListSettings(
    ListViews.SHOP_AND_GO_ORDER_LIST,
  );
  const {
    hasPresetsChanged,
    onPresetChange,
    onPresetDelete,
    onPresetSave,
    onPresetUpdate,
    getPresetNameToDelete,
    presets,
    selectedPreset,
    setPresetIdToDelete,
  } = useFilterPresets({
    params,
    getUrl: shopAndGoOrderListUrl,
    storageUtils,
    reset: () => "",
  });

  usePaginationReset(shopAndGoOrderListUrl, params, settings.rowNumber);

  const intl = useIntl();
  const { channel, availableChannels } = useAppChannel(false);
  const user = useUser();
  const channels = user?.user?.accessibleChannels ?? [];

  const noChannel = !channel && typeof channel !== "undefined";
  const channelOpts = availableChannels ? mapNodeToChoice(channels) : null;

  const [changeFilters, resetFilters, handleSearchChange] = useFilterHandlers({
    createUrl: shopAndGoOrderListUrl,
    getFilterQueryParam,
    params,
    defaultSortField: DEFAULT_SORT_KEY,
    hasSortWithRank: false,
    keepActiveTab: true,
  });

  const [openModal, closeModal] = createDialogActionHandlers<
    ShopAndGoOrderListUrlDialog,
    ShopAndGoOrderListUrlQueryParams
  >(navigate, shopAndGoOrderListUrl, params);

  const paginationState = createPaginationState(settings.rowNumber, params);

  const queryVariables = React.useMemo(
    () => ({
      ...paginationState,
      filter: getFilterVariables(params),
      sort: getSortQueryVariables(params),
    }),
    [params, settings.rowNumber],
  );
  const { data, loading } = useShopAndGoOrdersListQuery({
    displayLoader: true,
    variables: queryVariables,
  });

  const paginationValues = usePaginator({
    pageInfo: data?.shopAndGoOrders?.pageInfo,
    paginationState,
    queryString: params,
  });

  const handleSort = createSortHandler(navigate, shopAndGoOrderListUrl, params);

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <ShopAndGoOrderListPage
        settings={settings}
        currentTab={selectedPreset}
        disabled={loading}
        filterOpts={getFilterOpts(params, channelOpts)}
        shopAndGoOrders={mapEdgesToItems(data?.shopAndGoOrders)}
        sort={getSortParams(params)}
        onAdd={() => openModal("create-shop-and-go-order")}
        onUpdateListSettings={updateListSettings}
        onSort={handleSort}
        onSearchChange={handleSearchChange}
        onFilterChange={changeFilters}
        onTabSave={() => openModal("save-search")}
        onTabDelete={(tabIndex: number) => {
          setPresetIdToDelete(tabIndex);
          openModal("delete-search");
        }}
        onTabChange={onPresetChange}
        onTabUpdate={onPresetUpdate}
        initialSearch={params.query || ""}
        tabs={presets.map(tab => tab.name)}
        onAll={resetFilters}
        onSettingsOpen={() => navigate(shopAndGoOrderSettingsPath)}
        params={params}
        hasPresetsChanged={hasPresetsChanged()}
      />
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={onPresetSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={onPresetDelete}
        tabName={getPresetNameToDelete()}
      />
    </PaginatorContext.Provider>
  );
};

export default ShopAndGoOrderList;
