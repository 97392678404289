// @ts-strict-ignore
import { Button } from "@dashboard/components/Button";
import CardTitle from "@dashboard/components/CardTitle";
import Date from "@dashboard/components/Date";
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import Skeleton from "@dashboard/components/Skeleton";
import TableRowLink from "@dashboard/components/TableRowLink";
import { PreparationSlipFragment } from "@dashboard/graphql";
import {
  Card,
  CardContent,
  TableBody,
  TableCell,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import { sprinkles } from "@saleor/macaw-ui/next";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(
  () => ({
    card: {
      overflow: "hidden",
    },
    colAction: {
      button: {
        padding: "0",
      },
      padding: "0 0.5rem",
      width: "auto",
    },
    colNumber: { width: "100%" },
    colNumberClickable: {
      cursor: "pointer",
      width: "100%",
    },
    invoicesTable: {
      display: "flex",
    },
    invoicesTableBody: {
      width: "100%",
    },
  }),
  { name: "PreparationSlip" },
);

export interface PreparationSlipProps {
  preparationSlips: PreparationSlipFragment[];
  onPreparationSlipGenerate: () => void;
  onPreparationSlipClick: (preparationSlipId: string) => void;
}

const PreparationSlip: React.FC<PreparationSlipProps> = props => {
  const { preparationSlips, onPreparationSlipGenerate, onPreparationSlipClick } = props;

  const classes = useStyles(props);

  const intl = useIntl();

  const generatedPreparationSlips = preparationSlips?.filter(
    preparationSlip => preparationSlip.status === "SUCCESS",
  );
  return (
    <Card className={classes.card}>
      <CardTitle
        title={intl.formatMessage({
            id: "Gzg8hy",
            defaultMessage: "Preparation Slip",
            description: "section header",
        })}
        toolbar={
            onPreparationSlipGenerate && (
            <Button
                onClick={onPreparationSlipGenerate}
                className={sprinkles({ marginRight: 0.5 })}
            >
                <FormattedMessage
                id="e0RKe+"
                defaultMessage="Generate"
                description="generate preparation slip button"
                />
            </Button>
            )
        }
      />
      <CardContent>
        {!generatedPreparationSlips ? (
          <Skeleton />
        ) : !generatedPreparationSlips?.length ? (
          <Typography color="textSecondary">
            <FormattedMessage
              id="hPB89Y"
              defaultMessage="No preparation slips to be shown"
            />
          </Typography>
        ) : (
          <ResponsiveTable className={classes.invoicesTable}>
            <TableBody className={classes.invoicesTableBody}>
            {generatedPreparationSlips.map(preparationSlip => (
              <TableRowLink key={preparationSlip?.id} hover={!!preparationSlip}>
                <TableCell
                  className={
                    onPreparationSlipClick
                      ? classes.colNumberClickable
                      : classes.colNumber
                  }
                  onClick={() => onPreparationSlipClick?.(preparationSlip?.id)}
                >
                  <FormattedMessage
                    id="m6IBe5"
                    defaultMessage="Preparation Slip"
                    description="preparation Slip number prefix"
                  />{" "}
                  {preparationSlip?.number}
                  <Typography variant="caption">
                    <FormattedMessage
                      id="F0AXNs"
                      defaultMessage="created"
                      description="preparation slip create date prefix"
                    />{" "}
                    <Date date={preparationSlip?.createdAt} plain />
                  </Typography>
                </TableCell>
              </TableRowLink>
            ))}
            </TableBody>
          </ResponsiveTable>
        )}
      </CardContent>
    </Card>
  );
};

PreparationSlip.displayName = "PreparationSlip";
export default PreparationSlip;
