import urlJoin from "url-join";
import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  FiltersWithKeyValueValues,
  Pagination,
  Sort,
  TabActionDialog,
} from "../types";
import { stringifyQs } from "@dashboard/utils/urls";

const shopAndGoSectionUrl = "/shop-and-go/";

export const shopAndGoListPath = shopAndGoSectionUrl;
export enum ShopAndGoOrderListUrlFiltersEnum {
  createdFrom = "createdFrom",
  createdTo = "createdTo",
  email = "email",
  fullname = "fullname",
  isClosed = "isClosed",
  dateCreated = "dateCreated",
  orderQuery = "orderQuery",
  selectedStore = "selectedStore",
  query = "query",
}

export enum ShopAndGoOrderListFitersWithMultipleValues {
  selectedStore = "selectedStore",
  fullName = "fullName",
  email = "email",
}

export type ShopAndGoOrderListUrlFilters =
  Filters<ShopAndGoOrderListUrlFiltersEnum> &
    FiltersWithKeyValueValues<ShopAndGoOrderListFitersWithMultipleValues>;

export enum ShopAndGoOrderListUrlSortField {
  email = "email",
  fullname = "fullname",
  phoneNumber = "phoneNumber",  
  dateCreated = "dateCreated",
  isClosed = "isClosed",
  selectedStore = "selectedStore",
  orderQuery = "orderQuery",
}

export type ShopAndGoOrdersListUrlSort = Sort<ShopAndGoOrderListUrlSortField>;
export type ShopAndGoOrderListUrlQueryParams
  = BulkAction &
    Dialog<ShopAndGoOrderListUrlDialog> &
    ShopAndGoOrderListUrlFilters &
    ShopAndGoOrdersListUrlSort &
    Pagination &
    ActiveTab;


export type ShopAndGoOrderUrlDialog = | "delete" | "delete-shop-and-go-order"
export type ShopAndGoOrderUrlQueryParams = BulkAction & Dialog<ShopAndGoOrderUrlDialog>


export const shopAndGoOrderUrl = (
  id: string,
  params?: ShopAndGoOrderListUrlQueryParams,
) => shopAndGoOrderPath(encodeURIComponent(id)) + "?" + stringifyQs(params);

export const shopAndGoOrderListUrl = (
  params?: ShopAndGoOrderListUrlQueryParams,
): string => {
  const shopAndGoOrderList = shopAndGoListPath;
  if (params === undefined) {
    return shopAndGoOrderList;
  } else {
    return urlJoin(shopAndGoOrderList, "?" + stringifyQs(params));
  }
};


export const shopAndGoOrderPath = (id: string) => urlJoin(shopAndGoSectionUrl, id);


export type ShopAndGoOrderListUrlDialog =
  | "delete"
  | "export"
  | "create-shop-and-go-order"
  | TabActionDialog;

export const shopAndGoOrderSettingsPath = urlJoin(
  shopAndGoSectionUrl,
  "settings",
);
