import EditorJS from "@editorjs/editorjs";

export async function clean(editor: EditorJS) {
  if (editor) {
    // Prevents race conditions
    await editor.isReady;
    editor.destroy();
  }
}

export function createFormData(file) {
  const formData = new FormData();

  formData.append(
    "operations",
    JSON.stringify({
      operationName: "contentImageUpload",
      variables: { file: null },
      query:
        "mutation contentImageUpload($file: Upload!) { fileUpload(file: $file) { uploadedFile { url contentType __typename } errors { code message } }}",
    }),
  );

  const map = {
    "1": ["variables.file"],
  };

  formData.append("map", JSON.stringify(map));
  formData.append("1", file);

  return formData;
}

export function isValidPdfFile(file, allowedType) {
  const fileType = file.type;
  return fileType === allowedType;
}
