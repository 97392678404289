import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import {
  ShopAndGoOrderDetailsQuery,
  ShopAndGoOrderErrorFragment,
} from "@dashboard/graphql";
import ShopAndGoOrderUpdateForm, { ShopAndGoUpdateFormData } from "./form";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import React from "react";
import useNavigator from "@dashboard/hooks/useNavigator";
import { shopAndGoOrderListUrl } from "@dashboard/shopAndGo/urls";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { TopNav } from "@dashboard/components/AppLayout";
import ShopAndGoOrderDetailsForm from "../ShopAndGoOrderDetailsForm/ShopAndGoOrderDetailsForm";
import Savebar from "@dashboard/components/Savebar";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ListProps, ListViews } from "@dashboard/types";

export enum ShopAndGoOrderPageTab {
  shopAndGoOrder = "shop and go orders",
}

export interface ShopAndGoOrderUpdatePageProps
  extends Pick<
    ListProps<ListViews.SHOP_AND_GO_FORM_DETAIL>,
    "onUpdateListSettings" | "settings"
  > {
  errors: ShopAndGoOrderErrorFragment[];
  disabled: boolean;
  shopAndGoOrder: ShopAndGoOrderDetailsQuery["shopAndGoOrder"];
  saveButtonBarState: ConfirmButtonTransitionState;
  onSubmit: (data: ShopAndGoUpdateFormData) => SubmitPromise;
}

export const ShopAndGoOrderUpdatePage: React.FC<
  ShopAndGoOrderUpdatePageProps
> = ({
  errors,
  disabled,
  shopAndGoOrder,
  saveButtonBarState,
  onSubmit,
}: ShopAndGoOrderUpdatePageProps) => {
  const navigate = useNavigator();

  const backHref = shopAndGoOrderListUrl();
  return (
    <ShopAndGoOrderUpdateForm
      shopAndGoOrder={shopAndGoOrder}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {({ data, change, submit, isSaveDisabled }) => (
        <DetailPageLayout gridTemplateColumns={1}>
          <TopNav href={backHref} title={"Order #" + shopAndGoOrder?.id} />
          <DetailPageLayout.Content>
            <ShopAndGoOrderDetailsForm
              data={data}
              disabled={disabled}
              errors={errors}
              onChange={change}
            />
            <CardSpacer />
            <Savebar
              onCancel={() => navigate(backHref)}
              onSubmit={submit}
              state={saveButtonBarState}
              disabled={isSaveDisabled}
            />
          </DetailPageLayout.Content>
        </DetailPageLayout>
      )}
    </ShopAndGoOrderUpdateForm>
  );
};
ShopAndGoOrderUpdatePage.displayName = "ShopAndGoOrderUpdatePage";
export default ShopAndGoOrderUpdatePage;
