import { ShopAndGoOrderSortField } from "@dashboard/graphql";
import { ShopAndGoOrderListUrlSortField } from "@dashboard/shopAndGo/urls";
import { createGetSortQueryVariables } from "@dashboard/utils/sort";

export const DEFAULT_SORT_KEY = ShopAndGoOrderListUrlSortField.dateCreated;

export function getSortQueryField(
  sort: ShopAndGoOrderListUrlSortField,
): ShopAndGoOrderSortField {
  switch (sort) {
    case ShopAndGoOrderListUrlSortField.fullname:
      return ShopAndGoOrderSortField.FULL_NAME;
    case ShopAndGoOrderListUrlSortField.email:
      return ShopAndGoOrderSortField.EMAIL;
    case ShopAndGoOrderListUrlSortField.dateCreated:
      return ShopAndGoOrderSortField.DATE_CREATED;
    case ShopAndGoOrderListUrlSortField.selectedStore:
      return ShopAndGoOrderSortField.SELECTED_STORE;
    default:
      return undefined;
  }
}

export const getSortQueryVariables =
  createGetSortQueryVariables(getSortQueryField);
