// @ts-nocheck

import T00434 from "./images/filters.png"
import L09785 from "./images/filters.png"

const product_filters = () => (<><p><img src={T00434} alt="new filters"/></p>
<p>Experience the new look and enhanced abilities of new fitering mechanism.
Easily combine any criteria you want, and quickly browse their values.</p>
</>)
const shop_and_go_filters = () => (<><p><img src={L09785} alt="new filters"/></p>
<p>Experience the new look and enhanced abilities of new fitering mechanism.
Easily combine any criteria you want, and quickly browse their values.</p>
</>)

export const AVAILABLE_FLAGS = [{
  name: "product_filters",
  displayName: "Products filtering",
  component: product_filters,
  visible: true,
  content: {
    enabled: true,
    payload: "default",
  }
},{
  name: "shop_and_go_filters",
  displayName: "Shop And Go filtering",
  component: shop_and_go_filters,
  visible: true,
  content: {
    enabled: true,
    payload: "default",
  }
}] as const;
